import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { partners1, partners2, partners3, partners4, partners5, partners6 } from "../../img";
const OurPartners = () => {
  const partners = [
    {
      id: 1,
      title:
        "Blackhawks Express INC (Sacramento, CA, USA – Dispatch support, ELD support and Accounting)",
      img: partners1,
    },
    {
      id: 2,
      title:
        "LLC Express Automatica (Uzbekistan - 2D and 3D projects and modeling for Civil Engineering construction sites)",
      img: partners2,
    },

    {
      id: 4,
      title: "LLC “Pahlavon” (Uzbekistan – SMM support)",
      img: partners4,
    },

    {
      id: 6,
      title:
        "NNT Express, INC (Chicago, IL, USA – Dispatch support, ELD support and Accounting )",
      img: partners3,
    },
    {
      id: 5,
      title:
        "LLC “SJOL” (Kazakhstan – 2D and 3D projects and modeling for Civil Engineering construction sites)",
      img: partners6,
    },
    {
      id: 3,
      title: "GEO TWO Co, Ltd (South Korea – Various kind of IT Support)",
      img: partners5,
    },
    // 
  ];
  return (
    <Container fluid className="home-goals-section py-5" id="goals">
      <Container>
        <Row>
          <Col md={12} xs={{ order: 1 }} className="home-about-description">
            <h1 className="purple clamp1">Our Partners </h1>
            <h1 className="clamp1">Companies that believe in us </h1>
          </Col>
        </Row>
        <Row className="">
          {partners.map((item, idx) => (
            <Col className="partners-card-container" lg={4} key={idx}>
              <div className="partners-card">
                <div className="img-container-partners">
                  <img src={item.img} alt="partner" className="img-fluid" />
                </div>
                <h4 className="partners-text">{item.title}</h4>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default OurPartners;

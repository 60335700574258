import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import AboutSection from "./AboutSection";
import ValuesSection from "./ValuesSection";
import { FaFacebook, FaTelegramPlane, FaYoutube } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import GoalSection from "./GoalSection";
import { HomeMain } from "../../img";
import ServiceSection from "./ServiceSection";
import "./home.css";
import OurPartners from "./OurPartners";
function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content screen">
          <Row>
            <Col md={7} className="home-header">
              {/* <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div> */}
              <div className="">
                <p className="clamp2">A COMPANY PROFILE OF</p>
                <h1 className="clamp  shimmer">NNT EXPRESS INC</h1>
              </div>
              <h1
                style={{ paddingBottom: 15 }}
                className="shimmer1 heading text-start clamp3 text-top"
              >
                YOU HAVE A TASK , WE HAVE THE SOLUTION
                {/* <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span> */}
              </h1>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={HomeMain}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
      <AboutSection />
      <ValuesSection />
      <GoalSection />
      <OurPartners />
      <ServiceSection />
    </section>
  );
}

export default Home;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import laptopImg from "../../Assets/about.png";

function Vacancies() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        dd
      </Container>
    </Container>
  );
}

export default Vacancies;
